.HeadLinks {
    grid-column: 1 / 2;
    grid-row: 3 / 7;
    display:flex;
    flex-direction:column;
    justify-content: space-evenly;
    
    background-color: transparent;
    border: none;
    position: fixed;
    left:2vw;
    top:25vh; 
    z-index: 1;
    color: black;
}

.NightHeadLinks {
    grid-column: 1 / 2;
    grid-row: 3 / 7;
    display:flex;
    flex-direction:column;
    justify-content: space-evenly;
    
    background-color: transparent;
    border: none;
    position: fixed;
    left:2vw;
    top:25vh; 
    z-index: 1;
    color: white;
}

.Linkedin{
    background-color: transparent;
    font-size:2em;
}
.Linkedin:hover{
    scale:2;
}

.Github{
    background-color: transparent;
    font-size:2em;
}
.Github:hover{
    scale:2;
}

.Email{
    background-color: transparent;
    font-size:2em;
}
.Email:hover{
    scale:2;
}

.HeadLink {
    color:inherit;
    text-decoration: none;
    min-width: 0;
}
