.DaBody{
    width:100%;
    height:100%;
    position: fixed;
}

.NightFont{
    color:white;
}

.WholeScreen {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
    width:100%;
    height:100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('https://media3.giphy.com/media/v1.Y2lkPTc5MGI3NjExenZ3cTIxbjRicWNwdmZ6aXRtZjcwOHlqb2Y3d3lzemxhd2t6dWpkciZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/YrTJKOe0FhQJAUXTyp/giphy.gif');
}

.NightWholeScreen {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
    color:white;
    width:100%;
    height:100%;
    background-size : cover ;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('https://media3.giphy.com/media/v1.Y2lkPTc5MGI3NjExcjkxYmtsczVzd2VwbjJ3dGJnYWlldmh1bnRkdno4emN5d2s4cHVjbCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/ODcgqrqIeNuewjiqow/giphy.gif');
}

.WholeContent{
    grid-column: 2 / 7;
    grid-row: 3 / 7;
}

.ButtonsRow{
    grid-column: 1 / 7;
    grid-row: 2 / 3;
    background-color: transparent;
    display: flex;
    justify-content: space-evenly;
    z-index: 1;
    flex-wrap: wrap;
    font-size: calc(10px + 1vw);
}

.AboutSection{
    grid-column: 2 / 7;
    grid-row: 3 / 7;
    width:48%;
    height:87%;
    position: fixed;
    top:13vh;
    left:50vw;
    display:flex;
    align-items: center;
    min-width: 0;
}

.NightAboutButton{
    background-color:transparent;
    font-size:2em;
    border:none;
    color:white;
}

.NightAboutButton:hover{
    cursor: pointer;
} 

.NightProjectsButton{
    background-color:transparent;
    font-size:2em;
    border:none;
    color:white;
}

.NightProjectsButton:hover{
    cursor: pointer;
}

.NightSkillsButton{
    background-color:transparent;
    font-size:2em;
    border:none;
    color:white;
}

.NightSkillsButton:hover{
    cursor: pointer;
}

.AboutButton{
    background-color:transparent;
    font-size:2em;
    border:none;
    color:black;
}

.AboutButton:hover{
    cursor: pointer;
}

.ProjectsButton{
    background-color:transparent;
    font-size:2em;
    border:none;
    color:black;
}

.ProjectsButton:hover{
    cursor: pointer;
}

.SkillsButton{
    background-color:transparent;
    font-size:2em;
    border:none;
    color:black;
}

.SkillsButton:hover{
    cursor: pointer;
}
