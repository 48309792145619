.ModeButton{
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    background-color: transparent;
    width:3%;
    height:3%;
    position: fixed;
    top:6vh;
    left:2vw;
    border:none;
    z-index: 10;
    font-size:2em;
    min-width: 0;
}
.ModeButton:hover{
    cursor:pointer;
    scale: 1.25;
}

.LangButton{
    background-color: transparent;
    position: fixed;
    top:2vh;
    left:2vw;
    border:none;
    z-index: 10;
    font-size:1em;
    min-width: 0;
}

.DarkLangButton{
    background-color: transparent;
    position: fixed;
    top:2vh;
    left:2vw;
    border:none;
    z-index: 10;
    font-size:1em;
    min-width: 0;
    color:white;
}

.DarkLangSelect{
    background-color: transparent;
    font-size: 1.2em;
    font-weight: bold;
    color:white;
    border:none;
}
.DarkLangSelect:hover{
    cursor: pointer;
    color:white;
}

.DarkLangOptions{
    font-size: 1em;
    color:white;
}

.LangSelect{
    background-color: transparent;
    font-size: 1.2em;
    font-weight: bold;
    border: none;
}

.LangSelect:hover{
    cursor: pointer;
}

.LangOptions{
    font-size: 1em;
}

.SunModeButton{
    color: white;
    min-width: 0;
}

.DarkModeButton{
    color: black;
    min-width: 0;
}

.DarkTheme{
    color:white;
}