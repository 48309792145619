.AllSkillsContent{
    width:48%;
    height:30%;
    overflow-y:scroll;
    scroll-behavior: smooth;
    position: fixed;
    left:25vw;
    top:40vh;
    text-align: center;
    transform-style: preserve-3d;
    z-index: 100;
}

.SkillsList{
    padding:0px;
    margin:0px;
    transform-style: preserve-3d;
    transform-origin: 50% 50%;
    transition: transform 0.5 ease;
    transform: rotateX(-20deg) translateZ(-100px);
    min-width: 0;
}

.SkillsText{
    margin: 5vh 0 5vh 0;
    font-size:2em;
    transform: rotateX(20deg);
    min-width: 0;
}